<template>
  <div class="workspace__container__view">
    <div class="workspace__container__view__header">
      <div class="workspace__container__view__header__actions">
        <button @click="createOrEdit()">{{ $t("workspace.client.create_client") }}</button>
      </div>
      <div class="workspace__container__view__header__filter">
        <div class="container__inputs">
          <div class="settings-input">
            <input type="text" v-model="filterModel.code" :placeholder="$t('workspace.client.filter_for') + $t('workspace.client.code')">
          </div>
          <div class="settings-input">
            <input type="text" v-model="filterModel.name" :placeholder="$t('workspace.client.filter_for') + $t('workspace.client.name')">
          </div>
          <div class="settings-input">
            <input type="text" v-model="filterModel.taxCode" :placeholder="$t('workspace.client.filter_for') + $t('workspace.client.taxCode')">
          </div>
        </div>
        <button :disabled="filterModel.code == '' && filterModel.name == '' && filterModel.taxCode == ''" @click="filterModel.code = ''; filterModel.name = ''; filterModel.taxCode = ''">{{ $t("workspace.clean_filter") }}</button>
      </div>
    </div>

    <table class="workspace__container__view__table">
      <thead>
        <tr>
          <th>{{ $t("workspace.client.code") }}</th>
          <th>{{ $t("workspace.client.name") }}</th>
          <th>{{ $t("workspace.client.taxCode") }}</th>
          <th>{{ $t("workspace.client.phone") }}</th>
          <th>{{ $t("workspace.client.contact") }}</th>
          <th class="actions">{{ $t("workspace.client.actions") }}</th>
        </tr>
      </thead>
      <tbody>
        <template v-if="listClients(filterModel).length > 0">  
          <tr v-for="item in listClients(filterModel)" :key="item.code">
            <td v-html="highlightText(item.code, filterModel.code)"></td>
            <td v-html="highlightText(item.name, filterModel.name)"></td>
            <td v-html="highlightText(item.taxCode, filterModel.taxCode)"></td>
            <td>{{item.phone}}</td>
            <td>{{item.contact}}</td>
            <td class="actions">
              <i class="material-icons" @click="createOrEdit(item)">edit</i>
              <i class="material-icons" @click="deleteItem(item)">delete</i>
            </td>
          </tr>
        </template>
        <tr v-if="listClients(filterModel).length == 0 && !isFilterActive">
          <td colspan="6">{{$t('workspace.not_have_loaded')}} <strong>{{ $t("left_menu.spot_programming.client") }}</strong>. {{$t('workspace.please')}} <strong style="text-decoration: underline; cursor: pointer" @click="createOrEdit()">{{ $t("workspace.client.create_client") }}</strong></td>
        </tr>
        <tr v-if="listClients(filterModel).length == 0 && isFilterActive">
          <td colspan="6">{{$t('workspace.none_results_with_filters')}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { ref } from '@vue/reactivity'
import { useStore } from 'vuex'
import { computed, onMounted } from '@vue/runtime-core';
import highlightText from '@/plugins/Mixin/highlightText.js';

export default {
  mixins: [highlightText],
  setup() {
    const store = useStore();
    const listClients = computed(() => filterModel => store.getters.client_clients(filterModel));
    const clientCategories = computed(() => store.getters.clientCategory_categories({name: '', code: ''}));
    const isFilterActive = computed(() => filterModel.value.code != '' || filterModel.value.name != '' || filterModel.value.taxCode != '');
    const filterModel = ref({
      code: '',
      name: '',
      taxCode: ''
    })

    onMounted(async () => {
      store.dispatch('loading_setActive', true);
      await store.dispatch('client_getAll');
      await store.dispatch('clientCategory_getAll');
      store.dispatch('loading_setActive', false);
    });

    async function createOrEdit(item) {
      await store.dispatch('modal_setActive', {active: 'SPOT-PROGRAMMING_CLIENT_CREATE-EDIT', props: item});
    }
    
    async function deleteItem(item) {
      const modelDelete = {...item};
      modelDelete.clientCategoryId = clientCategory(item.clientCategoryId);
      await store.dispatch('modal_setActive', {active: 'COMMON_DELETE', props: modelDelete, actionDelete: 'client_delete'});
    }

    function clientCategory(id) {
      const item = clientCategories.value.find(client => client.id == id);
      return item ? `${item.code} - ${item.name}` : '';
    }

    return {
      listClients,
      filterModel,
      isFilterActive,
      createOrEdit,
      deleteItem
    }
  }
}
</script>
<style lang="">
  
</style>